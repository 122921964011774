import React from "react";
import { Spinner } from "./Spinner";
import { Link } from "gatsby";
  
  const sizeClassnames = {
    big: "py-3 px-5 text-sm rounded-lg font-semibold text-sm md:text-base",
    small: "px-4 py-2 rounded-lg font-semibold text-sm", 
  };
  
  const colorClassnames = {
    primary:
      "text-white border-2 border-brand-500 bg-brand-500 hover:bg-brand-600 disabled:text-brand-600 disabled:bg-brand-600 focus:ring-4 focus:ring-brand-500 focus:ring-offset-2",
    secondary:
      "bg-white border-2 border-inset border-brand-500  font-medium text-brand-600 hover:bg-gray-50 focus:ring-offset-2 focus:border-black disabled:text-brand-300 focus:ring-4 focus:ring-brand-500",
    red:
    "text-white border-2 border-red-500  bg-red-500 hover:bg-red-600 disabled:text-red-600 disabled:bg-red-600 focus:ring-4 focus:ring-brand-500  focus:ring-offset-2",
  };
   
  
  const Button = ({
    children,
    size = "big",
    color = "primary",
    disabled,
    loading,
    icon,
    className = "",
    transition = true, 
    href,
    to,
    accessibilitytext,
    ...props
  }) => { 
    
    if(href) {
      return (<a  href={href} 
        className={`font-display  flex outline-none ${sizeClassnames[size]
      } ${transition ? `transition duration-200 ease-in-out` : ``} ${colorClassnames[color]
      } items-center justify-center ${className}`}
      {...props}>    <span className={loading ? "opacity-0" : `flex items-center`}>
      {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
      {children}
    {accessibilitytext?<span className="sr-only">{accessibilitytext}</span>:null}
    </span>
    </a>)
    }
    else if(to){
   
        return (<Link to={to} 
          className={`font-display  flex outline-none ${sizeClassnames[size]
        } ${transition ? `transition duration-200 ease-in-out` : ``} ${colorClassnames[color]
        } items-center justify-center ${className}`}
        {...props}>    <span className={loading ? "opacity-0" : `flex items-center`}>
        {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
        {children}
      {accessibilitytext?<span className="sr-only">{accessibilitytext}</span>:null}
      </span>
      </Link>)
 
    }

    return (
 
      <button
      className={`font-display  flex outline-none ${sizeClassnames[size]
      } ${transition ? `transition duration-200 ease-in-out` : ``} ${colorClassnames[color]
      } items-center justify-center ${className}`}
      {...props}
      >

        <span className={loading ? "opacity-0" : `flex items-center`}>
          {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
          {children}
        </span>
   
        {loading ? (
          <span className={`absolute`}>
            <Spinner size={size === "small" ? "2" : "4"} />
          </span>
        ) : null}
      </button>
  
  )};

  export default Button